import { Flex } from "ui/src/components/layout/Flex";
import { Text } from "ui/src/components/text/Text";
import { UnichainAnimatedText } from "ui/src/components/text/UnichainAnimatedText";
import { usePostTextElementPositionProps } from "ui/src/utils/layout";
import { isInterfaceDesktop } from "utilities/src/platform";
const DEFAULT_TEXT_PROPS = {
  color: "$neutral1",
  variant: "body2"
};
export function ElementAfterText({ element, text, wrapperProps, textProps }) {
  const { postTextElementPositionProps, onTextLayout } = usePostTextElementPositionProps();
  if (isInterfaceDesktop) {
    return <Flex row alignItems="center" {...wrapperProps}><Text {...DEFAULT_TEXT_PROPS} {...textProps}>{text}{element}</Text></Flex>;
  } else {
    return <Flex row alignItems="center" pr={postTextElementPositionProps ? "$spacing24" : void 0} {...wrapperProps}><Text {...DEFAULT_TEXT_PROPS} onTextLayout={onTextLayout} {...textProps}>{text}</Text><Flex {...postTextElementPositionProps}>{element}</Flex></Flex>;
  }
}
export function ElementAfterGradientText({
  element,
  text,
  wrapperProps,
  textProps,
  gradientTextColor,
  enabled,
  delayMs,
  gradientEndingXPlacement
}) {
  const { postTextElementPositionProps, onTextLayout } = usePostTextElementPositionProps();
  if (isInterfaceDesktop) {
    return <Flex row alignItems="center" {...wrapperProps}><UnichainAnimatedText
      gradientTextColor={gradientTextColor}
      enabled={enabled}
      delayMs={delayMs}
      {...DEFAULT_TEXT_PROPS}
      {...textProps}
    >{text}{element}</UnichainAnimatedText></Flex>;
  } else {
    return <Flex row alignItems="center" pr={postTextElementPositionProps ? "$spacing24" : void 0} {...wrapperProps}><UnichainAnimatedText
      gradientTextColor={gradientTextColor}
      enabled={enabled}
      delayMs={delayMs}
      gradientEndingXPlacement={gradientEndingXPlacement}
      {...DEFAULT_TEXT_PROPS}
      onTextLayout={onTextLayout}
      {...textProps}
    >{text}</UnichainAnimatedText><Flex {...postTextElementPositionProps}>{element}</Flex></Flex>;
  }
}
