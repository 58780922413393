"use strict";
import { TradeType } from "@uniswap/sdk-core";
import { wagmiConfig } from "components/Web3Provider/wagmiConfig";
import { clientToProvider } from "hooks/useEthersProvider";
import ms from "ms";
import { addTransaction, finalizeTransaction, updateTransactionInfo } from "state/transactions/reducer";
import {
  TransactionType
} from "state/transactions/types";
import { isPendingTx } from "state/transactions/utils";
import { call, cancel, delay, fork, put, race, select, take } from "typed-redux-saga";
import { TransactionStatus } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { Routing } from "uniswap/src/data/tradingApi/__generated__";
import { isL2ChainId } from "uniswap/src/features/chains/utils";
import {
  ApprovalEditedInWalletError,
  HandledTransactionInterrupt,
  TransactionStepFailedError,
  UnexpectedTransactionStateError
} from "uniswap/src/features/transactions/errors";
import {
  TransactionStepType
} from "uniswap/src/features/transactions/swap/types/steps";
import { isUniswapX } from "uniswap/src/features/transactions/swap/utils/routing";
import { parseERC20ApproveCalldata } from "uniswap/src/utils/approvals";
import { interruptTransactionFlow } from "uniswap/src/utils/saga";
import { isSameAddress } from "utilities/src/addresses";
import { percentFromFloat } from "utilities/src/format/percent";
import { Sentry } from "utilities/src/logger/Sentry";
import noop from "utilities/src/react/noop";
import { currencyId } from "utils/currencyId";
import { signTypedData } from "utils/signing";
import { getConnectorClient, getTransaction } from "wagmi/actions";
export function* handleSignatureStep({ setCurrentStep, step, ignoreInterrupt, account }) {
  const { throwIfInterrupted } = yield* watchForInterruption(ignoreInterrupt);
  addTransactionBreadcrumb({
    step,
    data: {
      domain: JSON.stringify(step.domain),
      values: JSON.stringify(step.values),
      types: JSON.stringify(step.types)
    }
  });
  setCurrentStep({ step, accepted: false });
  const signer = yield* call(getSigner, account.address);
  const signature = yield* call(signTypedData, signer, step.domain, step.types, step.values);
  yield* call(throwIfInterrupted);
  addTransactionBreadcrumb({ step, data: { signature }, status: "complete" });
  return signature;
}
export function* handleOnChainStep(params) {
  const { account, step, setCurrentStep, info, allowDuplicativeTx, ignoreInterrupt, onModification } = params;
  const { chainId } = step.txRequest;
  addTransactionBreadcrumb({ step, data: { ...info } });
  const duplicativeTx = yield* findDuplicativeTx(info, account, chainId, allowDuplicativeTx);
  if (duplicativeTx) {
    if (duplicativeTx.status === TransactionStatus.Confirmed) {
      addTransactionBreadcrumb({ step, data: { duplicativeTx: true, hash: duplicativeTx.hash }, status: "complete" });
      return duplicativeTx.hash;
    } else {
      addTransactionBreadcrumb({ step, data: { duplicativeTx: true, hash: duplicativeTx.hash }, status: "in progress" });
      setCurrentStep({ step, accepted: true });
      return yield* handleOnChainConfirmation(params, duplicativeTx.hash);
    }
  }
  const { throwIfInterrupted } = yield* watchForInterruption(ignoreInterrupt);
  setCurrentStep({ step, accepted: false });
  const { hash, nonce, data } = yield* call(submitTransaction, params);
  setCurrentStep({ step, accepted: true });
  yield* put(addTransaction({ from: account.address, info, hash, nonce, chainId }));
  if (step.txRequest.data !== data && onModification) {
    yield* call(onModification, { hash, data, nonce });
  }
  yield* call(throwIfInterrupted);
  return yield* handleOnChainConfirmation(params, hash);
}
function* handleOnChainConfirmation(params, hash) {
  const { step, shouldWaitForConfirmation = true, ignoreInterrupt } = params;
  if (!shouldWaitForConfirmation) {
    return hash;
  }
  if (ignoreInterrupt) {
    yield* call(waitForTransaction, hash, step);
    return hash;
  }
  const { interrupt } = yield* race({
    transactionFinished: call(waitForTransaction, hash, step),
    interrupt: take(interruptTransactionFlow.type)
  });
  if (interrupt) {
    throw new HandledTransactionInterrupt("Transaction flow was interrupted");
  }
  addTransactionBreadcrumb({ step, data: { txHash: hash }, status: "complete" });
  return hash;
}
function* submitTransaction(params) {
  const { account, step } = params;
  const signer = yield* call(getSigner, account.address);
  try {
    const response = yield* call([signer, "sendTransaction"], step.txRequest);
    return transformTransactionResponse(response);
  } catch (error) {
    if (error && typeof error === "object" && "transactionHash" in error) {
      return yield* recoverTransactionFromHash(error.transactionHash, step);
    }
    throw error;
  }
}
function* recoverTransactionFromHash(hash, step) {
  const transaction = yield* pollForTransaction(hash, step.txRequest.chainId);
  if (!transaction) {
    throw new TransactionStepFailedError({ message: `Transaction not found`, step });
  }
  return transformTransactionResponse(transaction);
}
function* pollForTransaction(hash, chainId) {
  const POLL_INTERVAL = 2e3;
  const MAX_POLLING_TIME = isL2ChainId(chainId) ? 12e3 : 24e3;
  let elapsed = 0;
  while (elapsed < MAX_POLLING_TIME) {
    try {
      return yield* call(getTransaction, wagmiConfig, { chainId, hash });
    } catch {
      yield* delay(POLL_INTERVAL);
      elapsed += POLL_INTERVAL;
    }
  }
  return null;
}
function transformTransactionResponse(response) {
  if ("data" in response) {
    return { hash: response.hash, data: response.data, nonce: response.nonce };
  }
  return { hash: response.hash, data: response.input, nonce: response.nonce };
}
export function* handleApprovalTransactionStep(params) {
  const { step } = params;
  const info = getApprovalTransactionInfo(step);
  return yield* call(handleOnChainStep, {
    ...params,
    info,
    *onModification({ hash, data }) {
      const { isInsufficient, approvedAmount } = checkApprovalAmount(data, step);
      yield* put(
        updateTransactionInfo({
          chainId: step.txRequest.chainId,
          hash,
          info: { ...info, amount: approvedAmount }
        })
      );
      if (isInsufficient) {
        throw new ApprovalEditedInWalletError({ step });
      }
    }
  });
}
function getApprovalTransactionInfo(approvalStep) {
  return {
    type: TransactionType.APPROVAL,
    tokenAddress: approvalStep.token.address,
    spender: approvalStep.spender,
    amount: approvalStep.amount
  };
}
function checkApprovalAmount(data, step) {
  const requiredAmount = BigInt(`0x${parseInt(step.amount, 10).toString(16)}`);
  const submitted = parseERC20ApproveCalldata(data);
  const approvedAmount = submitted.amount.toString(10);
  if (step.type === TransactionStepType.TokenRevocationTransaction) {
    return { isInsufficient: submitted.amount !== BigInt(0), approvedAmount };
  }
  return { isInsufficient: submitted.amount < requiredAmount, approvedAmount };
}
function isRecentTx(tx) {
  const currentTime = Date.now();
  const failed = tx.status === TransactionStatus.Failed;
  return !failed && currentTime - tx.addedTime < ms("30s");
}
function* findDuplicativeTx(info, account, chainId, allowDuplicativeTx) {
  if (allowDuplicativeTx) {
    return void 0;
  }
  const transactionMap = (yield* select((state) => state.localWebTransactions[chainId])) ?? {};
  const transactionsForAccount = Object.values(transactionMap).filter((tx) => isSameAddress(tx.from, account.address));
  return transactionsForAccount.find(
    (tx) => (isPendingTx(tx) || isRecentTx(tx)) && JSON.stringify(tx.info) === JSON.stringify(info)
  );
}
function* watchForInterruption(ignoreInterrupt = false) {
  if (ignoreInterrupt) {
    return { throwIfInterrupted: noop };
  }
  let wasInterrupted = false;
  const watchForInterruptionTask = yield* fork(function* () {
    yield* take(interruptTransactionFlow.type);
    wasInterrupted = true;
  });
  function* throwIfInterrupted() {
    if (wasInterrupted) {
      throw new HandledTransactionInterrupt("Transaction flow was interrupted");
    }
    yield* cancel(watchForInterruptionTask);
  }
  return { throwIfInterrupted };
}
function* waitForTransaction(hash, step) {
  while (true) {
    const { payload } = yield* take(finalizeTransaction.type);
    if (payload.hash === hash) {
      if (payload.status === TransactionStatus.Confirmed) {
        return;
      } else {
        throw new TransactionStepFailedError({ message: `${step.type} failed on-chain`, step });
      }
    }
  }
}
async function getProvider() {
  const client = await getConnectorClient(wagmiConfig);
  const provider = clientToProvider(client);
  if (!provider) {
    throw new UnexpectedTransactionStateError(`Failed to get provider during transaction flow`);
  }
  return provider;
}
async function getSigner(account) {
  return (await getProvider()).getSigner(account);
}
export function getSwapTransactionInfo(trade) {
  if (trade.routing === Routing.BRIDGE) {
    return {
      type: TransactionType.BRIDGE,
      inputCurrencyId: currencyId(trade.inputAmount.currency),
      inputChainId: trade.inputAmount.currency.chainId,
      outputCurrencyId: currencyId(trade.outputAmount.currency),
      outputChainId: trade.outputAmount.currency.chainId,
      inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
      outputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
      quoteId: trade.quote.requestId,
      depositConfirmed: false
    };
  }
  const slippage = percentFromFloat(trade.slippageTolerance);
  return {
    type: TransactionType.SWAP,
    inputCurrencyId: currencyId(trade.inputAmount.currency),
    outputCurrencyId: currencyId(trade.outputAmount.currency),
    isUniswapXOrder: isUniswapX(trade),
    ...trade.tradeType === TradeType.EXACT_INPUT ? {
      tradeType: TradeType.EXACT_INPUT,
      inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
      expectedOutputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
      minimumOutputCurrencyAmountRaw: trade.minimumAmountOut(slippage).quotient.toString()
    } : {
      tradeType: TradeType.EXACT_OUTPUT,
      maximumInputCurrencyAmountRaw: trade.maximumAmountIn(slippage).quotient.toString(),
      outputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
      expectedInputCurrencyAmountRaw: trade.inputAmount.quotient.toString()
    }
  };
}
export function addTransactionBreadcrumb({
  step,
  data = {},
  status = "initiated"
}) {
  Sentry.addBreadCrumb({
    level: "info",
    category: "transaction",
    message: `${step.type} ${status}`,
    data
  });
}
